import React from "react";
import DriverStats from "./DriverStats";


function ScrollSection({ drivers, driversContent}) {
    return (
        <div className="relative ml-[-110px] sm:ml-0">
            <div
                id="slider"
                className="flex w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth overscroll-contain pr-[40px] md:justify-center scrollbar-hide scrollbar-hide::-webkit-scrollbar">
                {drivers.map((driver, index) => (
                    <DriverStats key={index} driver={driver} driversContent={driversContent} />
                ))}
            </div>
        </div>
    );
}

export default ScrollSection;
