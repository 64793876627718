import React from 'react';
import Badge from './Badge';

function Hero({ hero, badgeLogo }) {
  return (
    <section
      className={`flex flex-col max-h-content relative min-h-[800px] bg-black pt-[80px] pb-[10px] px-[24px] bg-hero bg-cover bg-center lg:bg-bottom lg:min-h-[1000px] xl:min-h-[874px] xl:bg-contain xl:bg-no-repeat`}>
      {' '}
      <div className="w-full  max-h-content ">
        <h1 className="text-white text-[48px] font-bold uppercase tracking-[4px] leading-[120%] md:leading-[60px] text-center max-w-[300px] sm:max-w-[500px] mx-[auto] mb-[14px] lg:pb-[10px] lg:max-w-[930px] lg:text-[96px] lg:tracking-[10px] lg:leading-[96px] ">
          {hero.heading}
        </h1>
      </div>
      <div className="flex flex-col  flex-1 justify-between">
        <span className="text-black text-[16px] md:text-[20px] text-center block">
          {hero.subheading}
        </span>
        <span className=" w-full text-[14px] text-[#9D9EA3] text-right md:text-right block mb-[25px] max-w-[900px] mx-auto">
          {hero.carDetail}
        </span>
      </div>
      <Badge />
    </section>
  );
}

export default Hero;
