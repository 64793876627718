export function truncate(string, length) {
    try {
        if (string.length > length) {
            return string.substring(0, string.lastIndexOf(' ', length)) + '...';
        } else {
            return string;
        }
    } catch (err) {
        return string;
    }
}
