export const fetcher = async ({
    url,
    type = 'GET',
    body,
    headers,
    throw_error = true,
}) => {
    try {
        const response = await fetch(url, {
            method: type,
            body: body,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
        });
        // console.log("TEST", {
        //     status: response.status,
        //     statusText: response.statusText,
        //     url: response.url,
        //     response,
        // })
        if (response.status !== 200) {
            if (throw_error) {
                throw new Error(`Error type: ${type}`, {
                    cause: response,
                });
            }
            return {
                is_error: true,
                message: `Error type: ${type}`,
                cause: response,
            };
        }
        const data = await response.json();
        if (throw_error) {
            return data;
        }
        return {
            is_error: false,
            data,
        };
    } catch (error) {
        if (throw_error) {
            throw new Error(`Error type: ${type}`, {
                cause: error?.cause || error,
            });
        }
        return {
            is_error: true,
            message: error.message,
            cuase: error?.cause || error,
        };
    }
};
