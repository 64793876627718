import { useState, useEffect } from 'react';

const useCountdown = (targetDate) => {
    const countdownDate = new Date(targetDate).getTime();

    const [countdown, setCountdown] = useState(
        countdownDate - new Date().getTime()
    )

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(countdownDate - new Date().getTime())
        }, 1000)
        return () => clearInterval(interval)
    }, [countdownDate])

    return getReturnValues(countdown);
}

const getReturnValues = (countdown) => {
    const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

    return [
        formatNumbersWithLeadingZero(days),
        formatNumbersWithLeadingZero(hours),
        formatNumbersWithLeadingZero(minutes),
        formatNumbersWithLeadingZero(seconds)
    ]
}

const formatNumbersWithLeadingZero = (number) => {
    return number < 10 ? `0${number}` : number;
}

export {useCountdown};