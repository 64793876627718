import { useState } from "react";
import { useCountdown } from "../../hooks/useCountdown";
import ShowCount from "./ShowCount";
import TimeExpiredMessage from "./TimeExpiredMessage";

function CountdownTimer({ targetDate, raceName }) {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    const [showTimer, setShowTimer] = useState(true);

    //console.log(days, hours, minutes, seconds)

    if (days + hours + minutes + seconds <= 0) {
        return <TimeExpiredMessage />;
    }

    return (
        <div className="bg-[#FFCC00] py-[16px]">
            <div className=" cursor-pointer text-center" onClick={() => setShowTimer(!showTimer)}>
                <p className="text-[14px] md:text-[16px] tracking-[0.1em] uppercase">
                    {/* Todo: Colin - remove hardcoded text */}
                    Next race in <span className="font-bold">{raceName}</span>
                </p>
            </div>
            {showTimer && <ShowCount days={days} hours={hours} minutes={minutes} seconds={seconds} />}
        </div>
    );
}

export default CountdownTimer;
