import NextRaceButton from './NextRaceButton';
import PlacingBadge from './PlacingBadge';
import Flag from '../Flag';

function RacingPageHero({ racingHeroContent }) {
  const {
    heroImageBg,
    placeText,
    trackMap,
    raceNumber,
    date,
    raceName,
    flag,
    raceDuration,
    turns,
    raceDistance,
    description,
    nextRaceDetails,
  } = racingHeroContent;

  // console.log('racingHeroContent::: ', racingHeroContent);

  //   let backgroundImageClass = '';

  //   backgroundImageClass +=
  //     'bg-black text-white py-[80px] lg3:py-[40px] px-[20px] text-center lg3:text-left bg-center bg-no-repeat';

  const raceBackground = {
    sebring: 'bg-racingHeroSebring',
    portimao: 'bg-racingHeroPortimao',
    leMans: 'bg-racingHeroLeMans',
    monza: 'bg-racingHeroMonza',
    spaFrancorchamps: 'bg-racingHeroSpaFrancorchamp',
    bahrain: 'bg-racingHeroBahrain',
    fuji: 'bg-racingHeroFuji',
  };
  // console.log('raceBackground[heroImageBg]: ', raceBackground[heroImageBg]);
  const raceTrack = trackMap => {
    // console.log('trackMap::: ', trackMap);
    switch (trackMap) {
      case 'sebring':
        return '/assets/racetrack-sebring.svg';
      case 'portimao':
        return '/assets/racetrack-portimao.svg';
      case 'leMans':
        return '/assets/racetrack-le-mans.svg';
      case 'monza':
        return '/assets/racetrack-monza.svg';
      case 'spaFrancorchamps':
        return '/assets/racetrack-spa-francorchamps.svg';
      case 'bahrain':
        return '/assets/racetrack-bahrain.svg';
      case 'fuji':
        return '/assets/racetrack-fuji.svg';
      default:
        break;
    }
  };

  // console.log('HERO backgroundImage: ', backgroundImageClass);
  // console.log('HERO: ', heroImageBg);
  // console.log(
  //     'nextRaceDetails::: ',
  //     nextRaceDetails,
  //     Object.keys(nextRaceDetails).length,
  //     Object.keys(nextRaceDetails).length > 0
  // );

  return (
    <div
      className={`bg-black text-white py-[80px] lg3:py-[40px] px-[20px] text-center lg3:text-left bg-center bg-cover bg-no-repeat ${raceBackground[heroImageBg]}`}>
      <div className="relative lg3:max-w-[1640px] mx-auto">
        <div className="lg3:flex lg3:flex-row-reverse lg3:justify-between lg3:pt-[180px] lg3:pb-[240px]">
          <img
            className="mb-[40px] text-center mx-auto block lg3:m-0 max-w-[120px] lg3:max-w-[620px] z-0"
            src={raceTrack(trackMap)}
            alt="race track map"
          />
          <div className="lg3:max-w-[800px] z-20">
            {placeText && (
              <PlacingBadge
                placeText={placeText}
                styles={'hidden lg3:block lg:mb-4'}
              />
            )}
            <div className="text-[24px] font-bold uppercase tracking-[0.1em]">
              <span className="text-[#FFCC00] mr-[24px] lg3:mr-[18px]">
                {raceNumber}
              </span>
              <span>{date}</span>
            </div>
            <div className="lg3:flex">
              <div className="flex mt-[24px] justify-center">
                <Flag path={flag} size={{ width: '103', height: '60' }} />
              </div>
              <h1 className="text-[48px] font-bold break-words lg3:text-[96px] tracking-[0.25em] lg3:ml-[16px] lg3:leading-[105px] uppercase">
                {raceName}
              </h1>
            </div>
            <div
              className={`text-[#9D9EA3] uppercase text-[24px] lg3:text-[36px] lg3:mb-[30px] tracking-[0.2em]`}>
              <span>{raceDuration} </span>
              <span className="hidden lg3:inline-block">
                {' '}
                •• {turns} •• {raceDistance}
              </span>
            </div>
            {/* {isMobile && <PlacingBadge placeText={placeText} />} */}
            {placeText && (
              <PlacingBadge
                placeText={placeText}
                styles={'lg3:hidden mx-auto'}
              />
            )}
            <p className="text-[20px] lg3:max-w-[80%]">{description}</p>
          </div>
        </div>
        {Object.keys(nextRaceDetails)?.length ? (
          <NextRaceButton nextRaceData={nextRaceDetails} />
        ) : null}
      </div>
    </div>
  );
}

export default RacingPageHero;
