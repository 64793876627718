import React from "react";

function MobileNav({ isOpen, toggleMobileMenu }) {
    const handleClick = (e) => {
        toggleMobileMenu();
    };

    return (
        <button
            onClick={(e) => handleClick(e)}
            className=" min-h-[44px] min-w-[44px] flex justify-center items-center md:hidden">
            {isOpen ? (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                </svg>
            )}
        </button>
    );
}

export default MobileNav;
