import React from 'react';
import ScrollSection from './ScrollSection';
import Icon from './Icon';
import Button from './Button';
import { Link } from 'react-router-dom';

function MeetTheTeam({ meetTheTeam, drivers, driversContent }) {
    const meetTheTeamParagraphs = () => {
        const strArray = meetTheTeam?.text.split('\n');
        // console.log('strArray', strArray);
        return (
            <>
                <p className="text-[18px] leading-[150%] text-[#D9D9D9]">
                    {strArray[0]}
                </p>
                <p className="text-[18px] leading-[150%] text-[#D9D9D9] mt-2">
                    {strArray[1]}
                </p>
            </>
        );
    };

    return (
        <section className="pt-[140px] bg-[#777] text-white bg-teamJota bg-cover bg-center">
            <span className="uppercase text-[#E3B266] tracking-[2px] text-center block">
                {meetTheTeam.subheading}
            </span>
            <h2 className="uppercase text-[40px] tracking-[6px] font-bold text-center mb-[28px]">
                {meetTheTeam.heading}
            </h2>
            <div className="relative border border-black mx-[24px] p-[24px] pb-[60px] mb-[60px] leading-[32px] md:max-w-[840px] md:mx-auto">
                {meetTheTeamParagraphs()}
                <Link to={meetTheTeam.cta.link}>
                    <Button
                        absolute={true}
                        additionalTwStyles="bg-black py-[10px] w-[90%] md:w-[260px]"
                    >
                        {meetTheTeam.cta.text}&nbsp;{' '}
                        <Icon stroke="#E3B266" classNames={'mt-2'} />
                    </Button>
                </Link>
            </div>
            <ScrollSection drivers={drivers} driversContent={driversContent} />
        </section>
    );
}

export default MeetTheTeam;
