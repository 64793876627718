import { Spacer } from '.';
function DriverQuote({ page, quote, quoteImage, name, position }) {
  return (
    <div
      className={`${
        page !== 'racing' ? 'bg-quote_background' : 'bg-[#FFCC00]'
      }`}>
      <div
        className={`sm:flex ${
          position === 'left' ? '' : 'sm:flex-row-reverse'
        } ss:w-[100%]  max-w-[1640px] mx-auto`}>
        <div className="w-full ">
          <img
            className="w-full h-full object-cover object-center	"
            src={quoteImage}
            alt={name}
          />
        </div>
        <Spacer />
        <div
          className={`w-full px-10 py-[80px] flex items-center ${
            position === 'left'
              ? 'sm:border-white sm:border-l-[8px]'
              : 'sm:border-white sm:border-r-[8px]'
          }`}>
          <div>
            <img
              width={28}
              src={
                page !== 'racing'
                  ? '/assets/quotation.svg'
                  : '/assets/quotation-white.svg'
              }
              alt=""
            />
            <p
              className={`${
                page !== 'racing' ? 'text-light_gray300' : 'text-black '
              } text-[24px] sm:text-[32px] leading-[36px] sm:leading-[48px]`}>
              {quote}
            </p>
            <p
              className={`font-thin text-[20px] ${
                page !== 'racing' ? 'text-[#E0D6C6]' : 'text-[#45484A]'
              }`}>
              - {name}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriverQuote;
