import React from "react";

// const { title, heading, engine, combinedPerformance, syntheticFuel, displacement, vehText } = vehicleStats;
function CarSpec({vehicleStats}) {
    const {title, heading, engine, combinedPerformance, syntheticFuel, displacement, vehText } = vehicleStats
    return (
        <section className="relative  bg-vehicleStatsMob md:bg-vehicleStats min-h-[780px] bg-cover bg-center overflow-x-hidden xxxl:bg-contain xxxl:bg-no-repeat bg-black ">
            <div className="bg-gradient-to-t from-black to-[rgba(0,0,0,0)] md:bg-gradient-to-r absolute left-0 right-0 top-0 bottom-0"></div>
            <div className=" text-white text-[16px] absolute w-[100%] bottom-[0] p-[24px] mb-[30px] md:max-w-[1640px] md:left-[50%] md:translate-x-[-50%]">
                <h2 className="text-white uppercase text-[40px] md:text-[64px] mb-[30px] md:mb-[120px] tracking-[0.2em] md:leading-[87px] font-bold leading-[54px] whitespace-pre-wrap">
                    {title}
                </h2>
                <h3 className="uppercase text-[#E3B266] tracking-[4px] mb-[12px]">{heading}</h3>

                <div className="md:flex">
                    <div className="flex border-b pl-0 border-[#45484A] p-[8px] md:border-0 ">
                        <div className="p-[8px] pl-0 border-r border-[#45484A] pr-[20px]">
                            <span className="text-[32px] font-bold block leading-[30px]"> {engine} </span>
                            <span className="text-[12px] uppercase tracking-[2.5px] text-[#767676] font-bold">
                                {" "}
                                {vehText.engine}{" "}
                            </span>
                        </div>
                        <div className=" p-[8px] pl-[20px] pr-[20px] border-[#45484A] md:border-r ">
                            <span className="text-[32px] font-bold block leading-[30px]"> {combinedPerformance} </span>
                            <span className="text-[12px] uppercase tracking-[2.5px] text-[#767676] font-bold">
                                {vehText.comPerf}
                            </span>
                        </div>
                    </div>

                    <div className="flex p-[8px] pl-0 md:pl-[8px]">
                        <div className="p-[8px] pl-0 md:pl-[8px] border-r border-[#45484A] pr-[20px]">
                            <span className="text-[32px] font-bold block leading-[30px]"> {syntheticFuel} </span>
                            <span className="text-[12px] uppercase tracking-[2.5px] text-[#767676] font-bold">
                                {vehText.synFuel}
                            </span>
                        </div>
                        <div className="p-[8px] pl-[20px]">
                            <span className="text-[32px] font-bold block leading-[30px]"> {displacement} </span>
                            <span className="text-[12px] uppercase tracking-[2.5px] text-[#767676] font-bold">
                                {vehText.displacement}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CarSpec;
