import ShareLink from './shareLink/ShareLink';
import { useNavigate } from 'react-router-dom';
import { truncate } from '../utils/helpers';
// import useLocalSorage from '../hooks/useLocalStorage';

function News({ articlePreviewContent, news }) {
    // console.log(
    //     'articlePreviewContent__: ',
    //     articlePreviewContent,
    //     'news__ ',
    //     news
    // );
    const { articlePreviews, shareWidget } = articlePreviewContent || {};
    const articles = articlePreviews.filter((_, index) => index < 3) || [];
    const navigate = useNavigate();
    const lang = 'en';
    return (
        <section
            id="news"
            className="bg-black bg-news bg-no-repeat bg-top px-[24px] py-[80px] ss:py-[120px] text-white"
        >
            <div className="md:max-w-[1640px] mx-auto">
                <div>
                    <span className="uppercase tracking-[3px] text-[16px]">
                        {news.tagline}
                    </span>
                    <h2 className="mb-[24px] uppercase text-[40px] tracking-[8px] font-bold">
                        {news.heading}
                    </h2>
                </div>

                <div
                    className="
                            sm:min-h-[680px] 
                            md:flex md:flex-wrap
                            lg:grid lg:grid-cols-3 lg:gap-[10px] lg:w-full lg:h-[400px] lg:bg-[lighgrey]  lg:auto-rows-fr	
                            "
                >
                    {articles.map((article, index) => {
                        // console.log('TEST__ ', article);
                        // console.log(
                        //     'TEST__ ',
                        //     lang,
                        //     ' : ',
                        //     article.customUrlName
                        // );
                        return (
                            <div
                                key={index}
                                className={`relative overflow-hidden bg-[#767676] mb-[16px] rounded h-[260px] border-[3px] border-[#45484A] bg-gradient-to-t from-[rgba(0,0,0,0.8)] hover:border-[#E3B266] hover:cursor-pointer 
                                            ss:max-w-[600px] ss:h-[340px] 
                                            sm:mx-auto
                                            md:min-w-[480px] md:h-auto
                                            lg:h-full lg:w-full lg:min-w-full lg:col-span-1 lg:first:col-span-2 lg:first:row-span-2 lg:mb-0 lg:m-0 `}
                            >
                                <div
                                    className="h-full w-full"
                                    onClick={() => {
                                        navigate(
                                            `/us/en/racing-gold/news/${article.customUrlName}`
                                        );
                                    }}
                                >
                                    <img
                                        src={article.thumbnailImageUrl}
                                        alt={'Hertz article preview thumbnail'}
                                        className="h-full object-cover min-w-full"
                                    />

                                    <div className="h-full p-[16px] absolute bottom-[0] w-full bg-gradient-to-t from-[rgba(0,0,0,9)] flex items-end">
                                        <div>
                                            <time
                                                dateTime={article.date}
                                                className="text-[#E3B266] text-[14px] uppercase tracking-[3px]"
                                            >
                                                {article.date}
                                            </time>
                                            <p className="text-[24px] md:text-[28px] tracking-[2px] text-white">
                                                {truncate(
                                                    article.mainHeading,
                                                    70
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <ShareLink
                                    shareWidget={shareWidget}
                                    articlePreviews={articles}
                                    article_path={`news/${lang}/${article.customUrlName}`}
                                    articlePreview={article}
                                    styles="absolute z-30 top-4 right-4 p-2 rounded-full bg-black bg-opacity-20 hover:bg-opacity-40"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}

export default News;
