import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
// import useLocalSorage from '../hooks/useLocalStorage';
import Spacer from './Spacer';
import { truncate } from '../utils/helpers';
import Icon from './Icon';
import NewsLongCard from './NewsLongCard';

function NewsarticlePreviewsSection({ articlePreviewContent }) {
    const { articlePreviews } = articlePreviewContent;
    // const { readMoreText, latestText } = articlePreviewContent.articlePageContent;
    const lang = 'en';
    // const {shareWidget} = articlePreviewContent;
    const [hover, setHover] = useState(
        articlePreviews.map(({ id }) => ({ id, hover: false }))
    );
    const updateHover = id => {
        setHover(cur => {
            const newHover = cur.map(item => {
                if (item.id === id) {
                    return { ...item, hover: !item.hover };
                }
                return item;
            });
            return newHover;
        });
    };
    const navigate = useNavigate();
    if (articlePreviews.length === 0) return null;
    return (
        <div className="bg-black">
            <NewsLongCard
                articlePreviews={articlePreviews}
                updateHover={updateHover}
                articlePreviewContent={articlePreviewContent}
            />
            <div className="hidden sm:block">
                <Spacer />
            </div>
            <div className=" w-full flex flex-col sm:flex-row sm:flex-wrap">
                {articlePreviews.map((article, index) => {
                    if (index === 0) {
                        return '';
                    }
                    const articleLength = articlePreviews.length;

                    let classes = '';

                    index % 2 !== 0 && index !== articleLength - 1
                        ? (classes += 'sm:border-r-[4px]')
                        : (classes += 'sm:border-l-[4px]');

                    if (index % 2 === 1 && index === articleLength - 1) {
                        classes +=
                            ' sm:border-r-[8px] sm:border-l-[0] sm:ml-[4px] sm:border-b-[0]';
                    }
                    return (
                        <div
                            key={index}
                            className={`w-full sm:w-[50%] border-white border-b-[8px] cursor-pointer ${classes}
                            `}
                            onClick={() =>
                                navigate(
                                    `/us/en/racing-gold/news/${article.customUrlName}`
                                )
                            }
                            onMouseEnter={() => updateHover(article?.id)}
                            onMouseLeave={() => updateHover(article?.id)}
                        >
                            <div
                                className={` relative max-h-[500px] min-h-[500px] sm:max-h-[400px] sm:min-h-[400px] max-w-[820px] ${
                                    index % 2 === 0 ? 'mr-auto' : 'ml-auto'
                                } `}
                            >
                                <div className="hidden sm:block w-full">
                                    <img
                                        className="w-full h-full min-h-[400px] max-h-[400px] object-cover object-center"
                                        src={article?.thumbnailImageUrl || ''}
                                        alt=""
                                    />
                                </div>
                                <div className="sm:hidden">
                                    <img
                                        className="w-full h-full min-h-[500px] max-h-[500px] object-cover object-center"
                                        src={
                                            article?.thumbnailImageUrlSmall ||
                                            ''
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="absolute top-0 w-full h-full ">
                                    <div className="w-full h-full flex items-end px-[20px] md:px-[40px] lg:px-[80px]">
                                        <div className="py-10 max-w-[100%] relative z-20">
                                            <div className="text-[#E3B266] text-[14px] tracking-[3px] uppercase">
                                                <span>
                                                    <span> {article.date}</span>
                                                </span>
                                            </div>
                                            <h1 className="text-[#F5F5F5] text-[24px]  md:text-[30px]  pb-[16px] pt-[8px]">
                                                {truncate(
                                                    article?.mainHeading,
                                                    70
                                                )}
                                            </h1>
                                            <NavLink
                                                className="text-[16px] text-white font-[500] flex items-center tracking-[3px]"
                                                to={`/news/${lang}/${article.customUrlName}`}
                                            >
                                                READ MORE &nbsp;{' '}
                                                <Icon
                                                    stroke="#E3B266"
                                                    classNames={'mt-2 '}
                                                />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`absolute top-0 w-full h-full bg-gradient-to-r ${
                                        hover.find(
                                            item => item.id === article?.id
                                        )?.hover
                                            ? 'to-black_rgba40 from-black_rgba70'
                                            : 'to-black_rgba50 from-black_rgba80'
                                    } z-10`}
                                ></div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {(articlePreviews.length - 1) % 2 === 1 && (
                <div className="hidden sm:block">
                    <Spacer />
                </div>
            )}
        </div>
    );
}

export default NewsarticlePreviewsSection;
