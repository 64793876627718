import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ footerContent }) {
  const year = new Date().getFullYear();
  const copyRight = `© ${year} Hertz Racing Gold. All rights reserved.`;

  return (
    <footer className="bg-black py-[80px] px-[24px]">
      {footerContent.map((item, index) => {
        return (
          <div key={index} className="text-white md:max-w-[1640px] md:mx-auto">
            <Link to="/">
              <img
                className="mb-4"
                src={'/assets/hertz-logo.png'}
                alt={'Hertz Logo'}
              />
            </Link>
            <p className="mb-[40px]">{item.text}</p>
            <span>{copyRight}</span>
            <button className="cmp-revoke-consent" id="cookie-button">
              {footerContent.cookieText}
            </button>
          </div>
        );
      })}
    </footer>
  );
}

export default Footer;
