import React, { useState } from 'react';
import Icon from '../Icon';

function ShareLink({
	shareWidget,
	articlePreviews,
	articlePreview,
	shareCurrentUrl,
	styles,
	article_path,
}) {

	const [copied, setCopied] = useState(
		articlePreviews.map(({ id }) => ({ id, copied: false }))
	);
	const [activeShare, setActiveShare] = useState(
		articlePreviews.map(({ id }) => ({ id, active: false }))
	);

	const currentUrl = window.location.href;
	const shareUrl = article_path ? currentUrl + article_path : currentUrl;
	const shareTitle = true ? articlePreview.mainHeading : articlePreview.shareLink.title;


	return (
		<div>
			<button
				onClick={() => {
					setActiveShare((cur) => {
						return cur.map((item) => {
							if (item.id === articlePreview.id) {
								return {
									...item,
									active: !item.active,
								};
							} else {
								return {
									...item,
									active: false,
								};
							}
						});
					});
				}}
				className={styles ? styles : ''}
			>
				<Icon stroke='white' path='share' viewBox='0 0 25 25' />
			</button>
			<div
				className={`absolute z-20 top-4 right-16 flex items-center justify-center text-[16px] min-w-[180px]  ${
					activeShare.find(({ id }) => id === articlePreview.id).active
						? 'block'
						: 'hidden'
				}`}
			>
				<div className='bg-black bg-opacity-90 p-4 rounded'>
					<p className='text-white text-center py-2'>
					{shareWidget.title} 
					</p>
					<div className='flex border-b-[1px] border-t-[1px] border-[#434244]'>
						<a
							href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
							target='_blank'
							rel='noreferrer'
							className='w-full p-2 flex items-center '
						>
							<Icon fill='#E3B266' classNames='block mr-2' path='facebook' />{' '}
							<span className='block  text-[#E3B266] hover:text-opacity-50'>
								{shareWidget.facebook}
							</span>
						</a>
					</div>
					<div className='flex border-b-[1px] border-[#434244]'>
						<a
							href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`}
							target='_blank'
							rel='noreferrer'
							className='w-full p-2 flex items-center '
						>
							<Icon fill='#E3B266' classNames='block mr-2' path='twitter' />{' '}
							<span className='block text-[#E3B266] hover:text-opacity-50'>
								{shareWidget.twitter}
							</span>
						</a>
					</div>
					{/* email */}
					<div className='flex border-b-[1px] border-[#434244] justify-center'>
						<a
							href={`mailto:?subject=${shareTitle}&body=${shareUrl}`}
							target='_blank'
							rel='noreferrer'
							className='w-full p-2 flex items-center '
						>
							<Icon
								// fill='#E3B266'
								viewBox={'0 0 28 28'}
								classNames='block -mb-3 mr-2'
								path='envelope'
							/>
							<span className='block text-[#E3B266] hover:text-opacity-50'>
								{shareWidget.email}
							</span>
						</a>
					</div>
					{window.isSecureContext && (
						<div className='flex justify-center'>
							<button
								onClick={() => {
									navigator.clipboard.writeText(shareUrl);
									setCopied((cur) => {
										const newCopied = [...cur];
										const index = newCopied.findIndex(
											(item) => item.id === articlePreview.id
										);
										newCopied[index].copied = true;
										return newCopied;
									});
									setTimeout(() => {
										setCopied((cur) => {
											const newCopied = [...cur];
											const index = newCopied.findIndex(
												(item) => item.id === articlePreview.id
											);
											newCopied[index].copied = false;
											return newCopied;
										});
										setActiveShare((cur) => {
											return cur.map((item) => {
												if (item.id === articlePreview.id && item.active) {
													return {
														...item,
														active: !item.active,
													};
												} else {
													return {
														...item,
														active: false,
													};
												}
											});
										});
									}, 2000);
								}}
								className='w-full p-2 flex items-center '
							>
								{' '}
								<Icon
									// fill='#E3B266'
									viewBox={'0 0 24 19'}
									classNames='block mr-2'
									path='link'
								/>{' '}
								<span className='block text-[#E3B266] hover:text-opacity-50'>
									{copied.find(({ id }) => id === articlePreview.id).copied
										? 'Link Copied'
										: 'Copy Link'}
								</span>
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default ShareLink;
