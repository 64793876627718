import { useState } from 'react';
import DesktopNav from './DesktopNav';
import MobileMenu from './MobileMenu';
import MobileNav from './MobileNav';
import { Link } from 'react-router-dom';

function Navbar({ data, raceNav }) {
  const { navLinks } = data;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showRaceNav, setShowRaceNav] = useState(false);
  const [showDriverNav, setShowDriverNav] = useState(false);

  const toggleRaceNav = () => {
    if (showDriverNav) setShowDriverNav(!showDriverNav);
    setShowRaceNav(!showRaceNav);
  };
  const toggleDriverNav = () => {
    if (showRaceNav) setShowRaceNav(!showRaceNav);
    setShowDriverNav(!showDriverNav);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const driverLinks = [
    {
      linkText: 'About Team Jota',
      linkUrl: '/us/en/racing-gold/drivers',
    },
    {
      linkText: 'António Félix da Costa',
      linkUrl:
        'https://www.hertz.com/us/en/racing-gold/team-jota/drivers/antonio-da-costa',
    },
    {
      linkText: 'Will Stevens',
      linkUrl:
        'https://www.hertz.com/us/en/racing-gold/team-jota/drivers/Will-Stevens',
    },
    {
      linkText: 'Yifei Ye',
      linkUrl:
        'https://www.hertz.com/us/en/racing-gold/team-jota/drivers/Yifei-Ye',
    },
  ];

  return (
    <header className="sticky top-0 z-50 px-[24px] py-[16px] bg-black border-b border-[#434244] text-white opacity-90">
      <div className="flex justify-between align-center md:max-w-[1640px] md:mx-auto">
        <Link to="/">
          <img
            className="max-h-[44px]"
            src={'/assets/hertz-logo.png'}
            alt={'Hertz Logo'}
          />
        </Link>
        <DesktopNav
          navLinks={navLinks}
          raceNav={raceNav}
          toggleRaceNav={toggleRaceNav}
          toggleDriverNav={toggleDriverNav}
          showRaceNav={showRaceNav}
          showDriverNav={showDriverNav}
          driverLinks={driverLinks}
        />
        <MobileNav
          isOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          toggleRaceNav={toggleRaceNav}
          toggleDriverNav={toggleDriverNav}
        />
      </div>
      {mobileMenuOpen && (
        <MobileMenu
          navLinks={navLinks}
          toggleMobileMenu={toggleMobileMenu}
          raceNav={raceNav}
          toggleRaceNav={toggleRaceNav}
          toggleDriverNav={toggleDriverNav}
          showRaceNav={showRaceNav}
          showDriverNav={showDriverNav}
          driverLinks={driverLinks}
        />
      )}
    </header>
  );
}

export default Navbar;
