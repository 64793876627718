function Badge({ styles }) {
    return (
        <div
            className={` ${
                styles
                    ? styles
                    : 'absolute left-[50%] translate-x-[-50%] bottom-[-90px]'
            }`}
        >
            <img
                className="max-w-[100px]"
                src={'/assets/badge.png'}
                alt={'Hertz Team Racing Badge'}
            />
        </div>
    );
}

export default Badge;
