import React, { useEffect } from 'react';
import Badge from '../components/Badge';
import { useParams } from 'react-router-dom';
import useFetcher from '../hooks/useFetcher';
import Loading from '../components/loading/Loading';
import ShareLink from '../components/shareLink/ShareLink';
import { useNavigate } from 'react-router-dom';

const URL = process.env.REACT_APP_API_URL;

function Article({ data }) {
    const { articlePreviews, shareWidget } = data.articlePreviewContent;

    const { id: customUrlName } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'virtualPageview',
            buildID: 'v1',
            dialect: 'en-us',
            firstTransactionDate: '',
            lastTransactionDate: '',
            memberOrGuest: 'guest',
            pageCategory: 'hertz racing gold',
            pageName: '/news/article',
            pageType: 'article',
            pos: 'us',
            returningCustomer: '',
            systemID: 'digital',
            userId: '',
            dropoffCorpFranchise: '',
            dropoffCountryCode: '',
            dropoffDate_mmddyy: '',
            'dropoffDate_yyyy-mm-dd': '',
            dropoffDay: '',
            dropoffDoW: '',
            dropoffLocationName: '',
            dropoffMonth_mm: '',
            dropoffOAGCode: '',
            dropoffState: '',
            dropoffTime24HR: '',
            dropoffYear_yyyy: '',
            isDomestic: '',
            isOneWay: '',
            pcCode: '',
            pickupCity: '',
            pickupCorpFranchise: '',
            pickupCountryCode: '',
            pickupDate_mmddyy: '',
            'pickupDate_yyyy-mm-dd': '',
            pickupDay: '',
            pickupDoW: '',
            pickupLocationName: '',
            pickupMonth_mm: '',
            pickupOAGCode: '',
            pickupState: '',
            pickupTime24HR: '',
            pickupYear_yyyy: '',
            rateCode: '',
            rentalDays: '',
            reservationProcess: '',
            vehicleBaseRateConverted: '',
            vehicleClass: '',
            vehicleCollectionName: '',
            vehiclePremiumStandard: '',
            vehicleType: '',
            voucherNumber: '',
            taxAmountConverted: '',
            timeOfPayment: '',
            totalFeesConverted: '',
            transactionType: '',
            memberIsUsingPoints: '',
            reservationLocationType: '',
            tripType: '',
            countryResidence: '',
            memberID: '',
            availablePoints: '',
            loginStatus: '',
            language: 'en',
        });
    }, []);

    const {
        data: apiData,
        error,
        loading,
    } = useFetcher(
        `${URL}/content?customUrlName=${customUrlName}&sectionLanguage=en`
    );
    if (error) {
        console.error(error);
        navigate('/us/en/racing-gold/news/');
    }
    if (loading) return <Loading />;

    return (
        <div>
            <div className="relative">
                <img
                    className="w-full h-[280px] sm:h-[350px] object-cover"
                    src={apiData.heroImageUrl}
                    alt=""
                />
                <Badge />
            </div>
            <section className=" px-[24px] ss:px-0  ss:max-w-[810px] mx-auto text-[#767676] text-[20px] leading-[30px] pt-[150px] pb-[80px]">
                <div className="flex justify-between items-center mb-[12px]">
                    <time
                        className="text-[#808285] text-[14px] sm:text-[16px]"
                        dateTime={apiData.date}
                    >
                        {apiData.date} • {apiData.readTime}{' '}
                    </time>

                    <div className="relative w-[46px] h-[46px] border-[0px]">
                        <ShareLink
                            shareWidget={shareWidget}
                            articlePreviews={articlePreviews}
                            articlePreview={apiData}
                            shareCurrentUrl={true}
                            styles="absolute z-30 p-2 rounded-full bg-black bg-opacity-20 hover:bg-opacity-40"
                        />
                        {/* <Icon path='share' href={apiData.shareLink.url} stroke='black' /> */}
                    </div>
                </div>

                {apiData.mainHeading && (
                    <h1 className="text-[36px] leading-[43px] sm:text-[56px] sm:leading-[62px] font-bold text-black mb-[24px]">
                        {apiData.mainHeading}
                    </h1>
                )}
                {apiData.content.map((item, index) => {
                    return (
                        <article key={index}>
                            {item.heading && (
                                <h2 className="text-black text-[32px] leading-[42px] mb-[20px]">
                                    {item.heading}
                                </h2>
                            )}
                            {item.linkText && item.linkUrl ? (
                                <a
                                    className="text-black block mb-[20px] hover:underline"
                                    href={item.linkUrl}
                                >
                                    {item.linkText}
                                </a>
                            ) : (
                                ''
                            )}
                            {item.paragraphs
                                ? item.paragraphs.map((para, index) => {
                                      return (
                                          <p key={index} className="mb-[20px]">
                                              {para}
                                          </p>
                                      );
                                  })
                                : ''}
                        </article>
                    );
                })}

                {/* {apiData.pdfUrl && (
					<a
						className='inline-flex items-center rounded bg-[#F5F5F5] py-[12px] px-[24px] hover:bg-[slategray]'
						download={apiData.pdfUrl}
						href={apiData.pdfUrl}
					>
						<Icon path='pdf' stroke='black' />
						<span className='text-black'> PDF Version</span>
					</a>
				)} */}
            </section>
        </div>
    );
}

export default Article;
