import { useEffect } from "react";
import { BreadcrumbsMenu, NewsArticlesSection, Spacer } from "../components";
function Articles({ data }) {
    const { articlePreviewContent, news, breadcrumbs } = data;

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "virtualPageview",
            buildID: "v1",
            dialect: "en-us",
            firstTransactionDate: "",
            lastTransactionDate: "",
            memberOrGuest: "guest",
            pageCategory: "hertz racing gold",
            pageName: "/news",
            pageType: "news",
            pos: "us",
            returningCustomer: "",
            systemID: "digital",
            userId: "",
            dropoffCorpFranchise: "",
            dropoffCountryCode: "",
            dropoffDate_mmddyy: "",
            "dropoffDate_yyyy-mm-dd": "",
            dropoffDay: "",
            dropoffDoW: "",
            dropoffLocationName: "",
            dropoffMonth_mm: "",
            dropoffOAGCode: "",
            dropoffState: "",
            dropoffTime24HR: "",
            dropoffYear_yyyy: "",
            isDomestic: "",
            isOneWay: "",
            pcCode: "",
            pickupCity: "",
            pickupCorpFranchise: "",
            pickupCountryCode: "",
            pickupDate_mmddyy: "",
            "pickupDate_yyyy-mm-dd": "",
            pickupDay: "",
            pickupDoW: "",
            pickupLocationName: "",
            pickupMonth_mm: "",
            pickupOAGCode: "",
            pickupState: "",
            pickupTime24HR: "",
            pickupYear_yyyy: "",
            rateCode: "",
            rentalDays: "",
            reservationProcess: "",
            vehicleBaseRateConverted: "",
            vehicleClass: "",
            vehicleCollectionName: "",
            vehiclePremiumStandard: "",
            vehicleType: "",
            voucherNumber: "",
            taxAmountConverted: "",
            timeOfPayment: "",
            totalFeesConverted: "",
            transactionType: "",
            memberIsUsingPoints: "",
            reservationLocationType: "",
            tripType: "",
            countryResidence: "",
            memberID: "",
            availablePoints: "",
            loginStatus: "",
            language: "en",
        });
    }, []);
    return (
        <div>
            <BreadcrumbsMenu breadcrumbs={breadcrumbs}/>
            <Spacer />
            <NewsArticlesSection 
                articlePreviewContent={articlePreviewContent}
                news={news} 
            />
        </div>
    );
}

export default Articles;
