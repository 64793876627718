import React from 'react';

function PlacingBadge({ placeText, styles }) {
  return (
    <div
      className={`text-center max-w-[200px] bg-center mt-[40px] mb-[80px] text-[16px] py-[8px] px-[12px] text-[black] uppercase bg-[url('https://hertz.com/assets/placeBadge.svg')] bg-no-repeat md:mb-0 ${styles}`}>
      {placeText}
    </div>
  );
}

export default PlacingBadge;
