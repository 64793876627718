import { useEffect, useState } from 'react';
import { fetcher } from '../utils/fetcher';

export default function useFetcher(url) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // console.log('url: ', url);

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);

                const response = await fetcher({ url });
                setData(response);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        })();
    }, [url]);

    return { data, error, loading };
}
