import React from "react";
import DateTimeDisplay from "./DateTimeDisplay";

function ShowCount({ days, hours, minutes, seconds }) {
    return (
        <div className="flex justify-center items-center ">
            <DateTimeDisplay value={days} type="days" />
            <p className="pb-[12px] md:pb-0 px-2 md:px-4 text-[#BB8417] text-[32px]">:</p>
            <DateTimeDisplay value={hours} type="hours" />
            <p className="pb-[12px] md:pb-0 px-2 md:px-4 text-[#BB8417] text-[32px]">:</p>
            <DateTimeDisplay value={minutes} type="minutes" />
            <p className="pb-[12px] md:pb-0 px-2 md:px-4 text-[#BB8417] text-[32px]">:</p>
            <DateTimeDisplay value={seconds} type="seconds" />
        </div>
    );
}

export default ShowCount;
