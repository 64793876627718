import './loading.css'

const Loading = ({loadingText}) => {
	return (
		<div id='fullPageSpinnerContainer' className='fullPageSpinnerModule'>
			<div className='fpsm_loadingAnimationContainer'>
				<div className='fpsm_loader border-[3px] border-[#E8E8E8] border-t-[3px] border-l-[3px] border-r-[3px] border-t-gold border-l-gold border-r-gold'></div>
				<div className='fpsm_loadingText'>{loadingText}</div>
			</div>
		</div>
	)
}

export default Loading
