import React from "react";
import CountdownTimer from "./countDownTimer/CountdownTimer";

function Countdown({ countdownBanner }) {
  const { raceSchedule } = countdownBanner;
  const currentDate = new Date();

  const getNextRace = () => {
    try {
      let next = raceSchedule?.map((race, index) => {
        const raceStartDate = new Date(race.startDate);
        const raceEndDate = new Date(race.endDate);
        if (currentDate <= raceStartDate) {
          // Race not started yet
          //console.log("RACE coming up: ", currentDate, " | ", raceStartDate);
          return { raceStartDate, raceLive: false, name: race.raceName };
        } else if (currentDate >= raceStartDate && currentDate <= raceEndDate) {
          // Race live
          //console.log("RACE Live: ", currentDate, " | ", raceStartDate);
          return { raceStartDate, raceLive: true, name: race.raceName };
        } else {
          return undefined;
        }
      });

      // filters out old races
      next = next.filter(function (element) {
        return element !== undefined;
      });

      return next;
    } catch (error) {
      return {};
    }
  };

  // console.log('getNextRace()', getNextRace());

  const showCountdown = () => {
    const { name, raceLive, raceStartDate } = getNextRace()[0] || {};

    if (name && raceLive) {
      return (
        <div className="bg-[#FFCC00] py-[18px]">
          <p className="text-[16px] tracking-[0.1em] text-center uppercase">
            <span className="font-bold">{name}</span> is currently Live!
          </p>
        </div>
      );
    } else if (name && !raceLive) {
      return <CountdownTimer targetDate={raceStartDate} raceName={name} />;
    } else {
      return null;
    }
  };

  return <>{showCountdown()}</>;
}

export default Countdown;
