import React from 'react';

function DriverStats({ driversContent, driver }) {
    // const links = item.item.socialLinks;
    const { image, imageAlt, name, country, homeTown, birthDate } = driver;

    // const socialLinks = () => {
    //     const arr = [];
    //     Object.entries(links).forEach((entry, index) => {
    //         const [key, value] = entry;
    //         arr.push(<Icon path={key} key={index} fill="#E3B266" href={value} classNames=" hover:opacity-50 " />);
    //     });
    //     return arr;
    // };

    const firstName = fullName => {
        const strArray = fullName.split(/(\s+)/);
        return strArray[0];
    };
    const lastName = fullName => {
        const strArray = fullName.split(/(\s+)/);

        if (strArray.length > 3) {
            const lastName = `${strArray[2]} ${strArray[4]} ${strArray[6]}`;
            return lastName;
        } else {
            return strArray[2];
        }
    };

    // console.log("driversContent", driversContent, driver);

    return (
        <div className="flex max-h-[400px] min-w-[440px] xl:border-r border-[black] mr-[8px] md:last:border-0">
            <img
                className="max-h-[420px] mr-[12px]"
                src={image}
                alt={imageAlt}
            />
            <div className="flex flex-col py-[24px] ">
                <div className="flex flex-col flex-nowrap w-[155px] py-4">
                    <span className="block uppercase text-[#E3B266] text-[16px] tracking-[2px]">
                        {firstName(name)}
                    </span>
                    <span className="block uppercase text-[#FFCC00] text-[24px] tracking-[5px] leading-[30px] whitespace-normal">
                        {lastName(name)}
                    </span>
                </div>
                <div className="py-4">
                    <span className="block uppercase text-[#9D9EA3] text-[12px]">
                        {driversContent.countryText}
                    </span>
                    <span className="block text-[18px]">{country}</span>
                </div>
                <div className="py-4">
                    <span className="block uppercase text-[#9D9EA3] text-[12px]">
                        {driversContent.hometown}
                    </span>
                    <span className="block text-[18px]">{homeTown}</span>
                </div>
                <div>
                    <span className="block uppercase text-[#9D9EA3] text-[12px]">
                        {driversContent.dob}
                    </span>
                    <span className="block text-[18px]">{birthDate}</span>
                </div>
                {/* <div>
                    <span className="flex max-w-[140px] flex-wrap justify-start gap-1">{socialLinks()}</span>
                </div> */}
            </div>
        </div>
    );
}

export default DriverStats;
