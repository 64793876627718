import { NavLink } from "react-router-dom";
import Flag from "./Flag";

function MobileMenu({
  toggleMobileMenu,
  navLinks,
  raceNav,
  showRaceNav,
  toggleRaceNav,
  showDriverNav,
  toggleDriverNav,
  driverLinks,
}) {
  // const onHomepage = window.location.pathname === "/";

  const { navTitle, raceLinks } = raceNav;

  return (
    <nav className=" z-50 bg-black opacity-90 text-white mt-[18px] md:hidden">
      <ul className={`border-[#45484A] pt-[14px] uppercase`}>
        <li className="py-[8px]">
          <div
            onClick={() => toggleDriverNav()}
            className="flex px-4 pl-0 hover:opacity-50"
          >
            <span className="mr-2"> Team Jota </span>

            {showDriverNav ? (
              <img src={"/assets/chev-up.svg"} alt="chev up icon" />
            ) : (
              <img src={"/assets/chev-down.svg"} alt="chev down icon" />
            )}
            {/* <Icon
                            path={"chevronUp"}
                            size={{ width: 28, height: 28 }}
                            stroke={showDriverNav ? "#FFCC00" : "#9D9EA3"}
                            classNames={` p-1 ${showDriverNav ? "rotate-180 pb-0 pt-2" : ""}`}
                            viewBox="0 0 16 16"
                        /> */}
          </div>
          <div className={`py-[4px] ${showDriverNav ? "block" : "hidden"}`}>
            <ul>
              {driverLinks?.map((link, index) => {
                return (
                  <a
                    href={link.linkUrl}
                    key={index}
                    onClick={() => toggleDriverNav()}
                  >
                    <li className="flex items-center p-[12px] hover:cursor-pointer hover:opacity-50">
                      <span className="">{link.linkText}</span>
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>
        </li>

        {navLinks.map((page, index) => {
          return (
            <li key={index}>
              <NavLink
                onClick={toggleMobileMenu}
                to={page.link}
                className={({ isActive }) =>
                  isActive
                    ? "text-[#FFCC00] block py-[14px]"
                    : "block py-[14px]"
                }
              >
                {page.title}
              </NavLink>
            </li>
          );
        })}
        {raceLinks.length > 0 && (
          <li className="py-[8px]">
            <div
              onClick={() => toggleRaceNav()}
              className="flex px-4 pl-0 hover:opacity-50"
            >
              <span className="mr-2"> {navTitle} </span>

              {showRaceNav ? (
                <img src={"/assets/chev-up.svg"} alt="chev up icon" />
              ) : (
                <img src={"/assets/chev-down.svg"} alt="chev down icon" />
              )}
            </div>
            <div className={`py-[4px] ${showRaceNav ? "block" : "hidden"}`}>
              <ul>
                {raceLinks?.map((link, index) => {
                  return (
                    <NavLink
                      to={`us/en/racing-gold/racing/${link.link}`}
                      key={index}
                      onClick={() => toggleRaceNav()}
                    >
                      <li className="flex items-center p-[12px] hover:cursor-pointer hover:opacity-50">
                        <span className="text-[#FFD100] mr-2">
                          {link.raceNumber}
                        </span>
                        <span className="mr-2">
                          {" "}
                          <Flag
                            path={link.flag}
                            size={{
                              width: 25,
                              height: 16,
                            }}
                          />{" "}
                        </span>
                        <span className="">{link.raceName}</span>
                      </li>
                    </NavLink>
                  );
                })}
              </ul>
            </div>
          </li>
        )}
        <li className="py-[8px]">
          <NavLink
            to="https://grandstandmerchandise.com/collections/hertz-team-jota"
            target="_blank"
            className={({ isActive }) => {
              return isActive
                ? "block py-[8px] border-b border-[#E3B266]"
                : "block py-[8px] hover:opacity-50";
            }}
            rel="noreferrer"
          >
            Apparel
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default MobileMenu;
