import { useState } from 'react';
import NewsLongCard from '../NewsLongCard';

function RacingNewsArticle({ articlePreviewContent }) {
  const { articlePreviews } = articlePreviewContent;
  const [hover, setHover] = useState(
    articlePreviews.map(({ id }) => ({ id, hover: false }))
  );

  const updateHover = id => {
    setHover(cur => {
      const newHover = cur.map(item => {
        if (item.id === id) {
          return { ...item, hover: !item.hover };
        }
        return item;
      });
      return newHover;
    });
  };
  return (
    <div className="bg-black">
      <NewsLongCard
        articlePreviews={articlePreviews}
        updateHover={updateHover}
        articlePreviewContent={articlePreviewContent}
      />
    </div>
  );
}

export default RacingNewsArticle;
