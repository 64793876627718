import { NavLink } from "react-router-dom";
import Flag from "./Flag";

function DesktopNav({
  navLinks,
  raceNav,
  toggleRaceNav,
  showRaceNav,
  showDriverNav,
  toggleDriverNav,
  driverLinks,
}) {
  const { navTitle, raceLinks } = raceNav;

  const handleClick = (e) => {
    toggleRaceNav();
  };
  const handleDriverClick = (e) => {
    toggleDriverNav();
  };

  // console.log('RaceLinks: ', raceLinks);
  return (
    <nav className="hidden md:flex w-full pl-[64px] items-center justify-between">
      <ul className="uppercase flex ">
        <li className="py-[8px] ml-[20px] relative">
          <div
            onClick={(e) => handleDriverClick(e)}
            className="flex px-4 hover:opacity-50"
          >
            <span className="mr-2"> Team Jota </span>

            {showDriverNav ? (
              <img src={"/assets/chev-up.svg"} alt="chev up icon" />
            ) : (
              <img src={"/assets/chev-down.svg"} alt="chev down icon" />
            )}
            {/* <Icon
              path={'chevronUp'}
              size={{ width: 28, height: 28 }}
              stroke={showDriverNav ? '#FFCC00' : '#9D9EA3'}
              classNames={` p-1 ${showDriverNav ? 'rotate-180 pb-0 pt-2' : ''}`}
              viewBox="0 0 16 16"
            /> */}
          </div>
          <div
            className={`rounded-[4px] shadow-xl min-w-[360px] px-[24px] py-[14px] bg-[#222222] absolute md:top-[58px] ${
              showDriverNav ? "block" : "hidden"
            }`}
          >
            <ul>
              {driverLinks?.map((link, index) => {
                return (
                  <a
                    href={link?.linkUrl}
                    key={index}
                    onClick={() => handleDriverClick()}
                  >
                    <li className="flex border-b-2 items-center last:border-b-0 p-[16px] border-b-[#434244] hover:cursor-pointer hover:opacity-50">
                      <span className="">{link.linkText}</span>
                    </li>
                  </a>
                );
              })}
            </ul>
          </div>
        </li>

        {navLinks?.map((navLink, index) => {
          return (
            <li key={index} className="mr-[64px]">
              <NavLink
                to={navLink.link}
                className={({ isActive }) =>
                  isActive
                    ? "block py-[8px] ml-[64px] border-b border-[#E3B266]"
                    : "block py-[8px] ml-[64px] hover:opacity-50"
                }
              >
                {navLink.title}
              </NavLink>
            </li>
          );
        })}
        {raceLinks.length > 0 && (
          <li className="py-[8px] ml-[20px] relative">
            <div
              onClick={(e) => handleClick(e)}
              className="flex px-4 hover:opacity-50"
            >
              <span className="mr-2"> {navTitle} </span>

              {showRaceNav ? (
                <img src={"/assets/chev-up.svg"} alt="chev up icon" />
              ) : (
                <img src={"/assets/chev-down.svg"} alt="chev down icon" />
              )}
            </div>

            <div
              className={`rounded-[4px] shadow-xl min-w-[360px] px-[24px] py-[14px] bg-[#222222] absolute md:top-[58px] ${
                showRaceNav ? "block" : "hidden"
              }`}
            >
              <ul>
                {raceLinks?.map((link, index) => {
                  return (
                    <NavLink
                      to={`us/en/racing-gold/racing/${link.link}`}
                      key={index}
                      onClick={() => handleClick()}
                    >
                      <li className="flex border-b-2 items-center last:border-b-0 p-[16px] border-b-[#434244] hover:cursor-pointer hover:opacity-50">
                        <span className="text-[#FFD100] mr-2">
                          {link.raceNumber}
                        </span>
                        <span className="mr-2">
                          {" "}
                          <Flag
                            path={link.flag}
                            size={{
                              width: 25,
                              height: 16,
                            }}
                          />{" "}
                        </span>
                        <span className="">{link.raceName}</span>
                      </li>
                    </NavLink>
                  );
                })}
              </ul>
            </div>
          </li>
        )}
        <li className="mr-[64px]">
          <NavLink
            to="https://grandstandmerchandise.com/collections/hertz-team-jota"
            target="_blank"
            className={({ isActive }) =>
              isActive
                ? "block py-[8px] ml-[64px] border-b border-[#E3B266]"
                : "block py-[8px] ml-[64px] hover:opacity-50"
            }
            rel="noreferrer"
          >
            Apparel
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default DesktopNav;
