import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import useLocalSorage from "../hooks/useLocalStorage";
import Icon from './Icon';

import { truncate } from '../utils/helpers';

function NewsLongCard({ articlePreviews, updateHover, articlePreviewContent }) {
    // console.log('PAGE CONTENT: ', articlePreviewContent);
    const [hover] = useState(
        articlePreviews.map(({ id }) => ({ id, hover: false }))
    );
    const { readMoreText, latestText } =
        articlePreviewContent.articlePageContent;
    const navigate = useNavigate();

    const lang = 'en';

    return (
        <div
            className="max-w-[1640px] mx-auto w-full cursor-pointer border-white border-b-[8px] sm:border-b-[0]"
            onMouseEnter={() => updateHover(articlePreviews[0]?.id)}
            onMouseLeave={() => updateHover(articlePreviews[0]?.id)}
            onClick={() =>
                navigate(
                    `/us/en/racing-gold/news/${articlePreviews[0].customUrlName}`
                )
            }
        >
            <div className=" w-full h-full relative min-h-[500px]">
                <div className="w-full h-full hidden sm:block">
                    <img
                        className="w-full h-full min-h-[500px] max-h-[500px] object-cover object-center"
                        src={articlePreviews[0]?.thumbnailImageUrl || ''}
                        alt=""
                    />
                </div>
                <div className="sm:hidden w-full h-full">
                    <img
                        className="w-full h-full min-h-[500px] max-h-[500px] object-cover "
                        src={articlePreviews[0]?.thumbnailImageUrlSmall || ''}
                        alt=""
                    />
                </div>
                <div className="absolute top-0 w-full h-full ">
                    <div className="w-full h-full flex items-end px-[20px] md:px-[40px] lg:px-[80px]">
                        <div className="py-[40px] md:py-[80px] max-w-[100%] md:max-w-[70%] relative z-20">
                            <div className="text-[#E3B266] text-[14px] uppercase tracking-[3px]">
                                <span>
                                    <span>{latestText}</span>
                                    <span> {articlePreviews[0]?.date}</span>
                                </span>
                            </div>
                            <h1 className="text-[#F5F5F5] text-[24px]  md:text-[40px] pb-[16px] pt-[8px]">
                                {truncate(articlePreviews[0]?.mainHeading, 70)}
                            </h1>
                            <NavLink
                                className="text-[16px] text-white font-[500] flex items-center tracking-[3px]"
                                to={`/news/${lang}/${articlePreviews[0].customUrlName}`}
                            >
                                {readMoreText} &nbsp;{' '}
                                <Icon stroke="#E3B266" classNames={'mt-2 '} />
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div
                    className={`absolute top-0 w-full h-full bg-gradient-to-r ${
                        hover.find(item => item.id === articlePreviews[0]?.id)
                            ?.hover
                            ? 'to-black_rgba40 from-black_rgba70'
                            : 'to-black_rgba50 from-black_rgba80'
                    }  z-10`}
                ></div>
            </div>
        </div>
    );
}

export default NewsLongCard;
