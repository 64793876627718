import React from "react";
import Icon from "./Icon";

function SocialLinks({stayConnected}) {
    return (
        <section className="bg-black px-[24px] py-[80px] ss:py-[140px] ">
            {stayConnected.map((item, index) => {
                return (
                    <div key={index} className="md:max-w-[1640px] md:mx-auto">
                        <h3 className="block text-[#E3B266] text-[24px] mb-[30px] ss:text-center ss:mb-[50px]">
                            {item.icons.heading}
                        </h3>
                        <div className="flex flex-wrap gap-10 justify-center">
                            {item.icons?.icons.map((icon, index) => {
                                return (
                                    <a
                                        key={index}
                                        href={icon.link}
                                        className="flex items-center text-[#767676]  p-2 hover:text-white ">
                                        <span className="flex mr-[12px]">
                                            <Icon
                                                path={icon.text.toLowerCase()}
                                                classNames="min-w-[28px] fill-current"
                                            />
                                        </span>
                                        <span className="block text-[20px]">{icon.text}</span>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </section>
    );
}

export default SocialLinks;
