import React from 'react';

function Button({ children, absolute, additionalTwStyles, click, disabled }) {
    const styles = ` 
        ${
            absolute
                ? ' absolute bottom-[-20px] left-[50%] translate-x-[-50%] flex justify-center items-center '
                : ''
        }
        block rounded uppercase tracking-[2px] text-center hover:bg-opacity-50 ss:mx-auto ${
            additionalTwStyles && additionalTwStyles
        } 
        `;

    return (
        <button onClick={click} disabled={disabled} className={styles}>
            {children}
        </button>
    );
}

export default Button;
