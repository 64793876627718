import React from "react";

function RaceDayPhotos({ racedayContent }) {
    const {title, images} = racedayContent;
    return (
        <div className="py-[40px] md:py-[140px] md:max-w-[1600px] md:mx-auto mx-[24px]">
            <h3 className="uppercase text-[36px] mb-[32px] tracking-[0.2em]">{title}</h3>
            {/* image gallery */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {images?.map((photo, index) => {
                    return (
                        <div key={index} className="max-h-[300px] overflow-hidden">
                            <img
                                className="w-full rounded-[4px] h-full object-cover object-center"
                                src={photo.src}
                                alt={photo.alt}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default RaceDayPhotos;
