import React from 'react'
import DriverQuote from '../DriverQuote'

function RacingQuoteSection({ quoteData }) {
  return (
    <div>
      <DriverQuote page={"racing"} {...quoteData} />
    </div>
  )
}

export default RacingQuoteSection