import { useEffect } from 'react';
import {
    Spacer,
    News,
    Hero,
    MeetTheTeam,
    CarSpec,
    Season,
    SocialLinks,
} from '../components';
import ApparelSection from '../components/ApparelSection';

function Home({ data }) {
    const {
        articlePreviewContent,
        raceDetails,
        season,
        vehicleStats,
        hero,
        meetTheTeam,
        news,
        drivers,
        driversContent,
        stayConnected,
    } = data;
    // console.log('vehicleStats____: ', data);
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'virtualPageview',
            buildID: 'v1',
            dialect: 'en-us',
            firstTransactionDate: '',
            lastTransactionDate: '',
            memberOrGuest: 'guest',
            pageCategory: 'hertz racing gold',
            pageName: '/',
            pageType: 'main',
            pos: 'us',
            returningCustomer: '',
            systemID: 'digital',
            userId: '',
            dropoffCorpFranchise: '',
            dropoffCountryCode: '',
            dropoffDate_mmddyy: '',
            'dropoffDate_yyyy-mm-dd': '',
            dropoffDay: '',
            dropoffDoW: '',
            dropoffLocationName: '',
            dropoffMonth_mm: '',
            dropoffOAGCode: '',
            dropoffState: '',
            dropoffTime24HR: '',
            dropoffYear_yyyy: '',
            isDomestic: '',
            isOneWay: '',
            pcCode: '',
            pickupCity: '',
            pickupCorpFranchise: '',
            pickupCountryCode: '',
            pickupDate_mmddyy: '',
            'pickupDate_yyyy-mm-dd': '',
            pickupDay: '',
            pickupDoW: '',
            pickupLocationName: '',
            pickupMonth_mm: '',
            pickupOAGCode: '',
            pickupState: '',
            pickupTime24HR: '',
            pickupYear_yyyy: '',
            rateCode: '',
            rentalDays: '',
            reservationProcess: '',
            vehicleBaseRateConverted: '',
            vehicleClass: '',
            vehicleCollectionName: '',
            vehiclePremiumStandard: '',
            vehicleType: '',
            voucherNumber: '',
            taxAmountConverted: '',
            timeOfPayment: '',
            totalFeesConverted: '',
            transactionType: '',
            memberIsUsingPoints: '',
            reservationLocationType: '',
            tripType: '',
            countryResidence: '',
            memberID: '',
            availablePoints: '',
            loginStatus: '',
            language: 'en',
        });
    }, []);

    return (
        <div>
            <Hero hero={hero} />
            <Spacer />
            <MeetTheTeam
                meetTheTeam={meetTheTeam}
                drivers={drivers}
                driversContent={driversContent}
            />
            <Spacer />
            <ApparelSection />
            <Spacer />
            <CarSpec vehicleStats={vehicleStats} />
            <Spacer />
            <Season raceResults={raceDetails} season={season} />
            <Spacer />
            <News articlePreviewContent={articlePreviewContent} news={news} />
            <Spacer />
            {/* {socialMedia.length > 0 ? <StayConnected socials={socialMedia} stayConnected={stayConnected} /> : null} */}
            <SocialLinks stayConnected={stayConnected} />
            <Spacer />
        </div>
    );
}

export default Home;
