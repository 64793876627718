import React, { useEffect, useState } from 'react';
import { Spacer } from '../components';
import { useNavigate, useParams } from 'react-router-dom';
import RaceDayPhotos from '../components/racingPages/RaceDayPhotos';
import RacingPageHero from '../components/racingPages/RacingPageHero';
import RacingReport from '../components/racingPages/RacingReport';
import Loading from '../components/loading/Loading';
import RacingNewsArticle from '../components/racingPages/RacingNewsArticle';
import RacingQuoteSection from '../components/racingPages/RacingQuoteSection';

const URL = process.env.REACT_APP_API_URL;

function Racing() {
    const { raceName } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        (async function () {
            try {
                setLoading(true);
                // console.log('UseEffect 2');
                const response = await fetch(
                    `${URL}/content?customUrlName=${raceName}&sectionType=racing&sectionLanguage=en`
                );
                // console.log('response status', response.status);
                response.status >= 400 && navigate('/us/en/racing-gold/');
                let data = await response.json();
                // console.log('data FETCHER', data);
                setData(data);
            } catch (err) {
                navigate('/us/en/racing-gold/');
                setError(err);
            } finally {
                setLoading(false);
            }
        })();
    }, [raceName, navigate]);

    // console.log('data', data);
    if (error) console.error(error);
    if (loading) return <Loading loadingText={'Loading...'} />;

    return (
        <>
            <RacingPageHero racingHeroContent={data.racingHeroSection} />
            <Spacer />
            {data?.racingReportSection?.title && (
                <>
                    <RacingReport
                        raceReportContent={data.racingReportSection}
                    />
                    <Spacer />
                </>
            )}
            {data?.racingArticlePreviewSection?.articlePreviews.length ? (
                <>
                    <RacingNewsArticle
                        articlePreviewContent={data.racingArticlePreviewSection}
                    />
                    <Spacer />
                </>
            ) : null}

            {data.racingQuoteSection.quote && (
                <>
                    <RacingQuoteSection quoteData={data.racingQuoteSection} />
                    <Spacer />
                </>
            )}
            {data?.racingRaceDaySection?.images?.length > 0 ? (
                <RaceDayPhotos racedayContent={data.racingRaceDaySection} />
            ) : null}
            {/* blog section - TBC */}
            {/* email section - TBC */}
        </>
    );
}

export default Racing;
