import React from 'react';
import Flag from '../Flag';
import Icon from '../Icon';
import { useParams, useNavigate } from 'react-router-dom';

function NextRaceButton({ nextRaceData }) {
    const { flag, name, buttonText } = nextRaceData;

    const { raceName } = useParams();

    const navigate = useNavigate();

    const links = [
        'us/en/racing-gold/racing/sebring',
        'us/en/racing-gold/racing/portimao',
        'us/en/racing-gold/racing/spa-francorchamps',
        'us/en/racing-gold/racing/le-mans',
        'us/en/racing-gold/racing/monza',
        'us/en/racing-gold/racing/fuji',
        'us/en/racing-gold/racing/bahrain',
    ];

    // find index of array element that matches raceName
    const index = links.indexOf(`us/en/racing-gold/racing/${raceName}`);
    // console.log('index: ', index);
    const link = links[index + 1] || '';
    // console.log('link: ', link);

    return (
        <div
            onClick={() => navigate('/' + link)}
            className="absolute w-[90%] z-50 bottom-[-130px] md:bottom-[-100px] left-1/2 -translate-x-1/2 inline-block hover:shadow-lg hover:cursor-pointer bg-black text-white border border-[#FFCC00] rounded-[4px] p-[12px] md:max-w-[520px] md:translate-x-0 md:left-auto md:right-0"
        >
            <div className="flex items-center">
                <Flag path={flag} />
                <span className="ml-[12px] text-[24px] md:text-[36px] block capitalize">
                    {name}
                </span>
            </div>
            <div className="flex items-center ">
                <span className="text-[16px] uppercase tracking-[0.1em]">
                    {buttonText}
                </span>
                <Icon
                    path="arrowRight"
                    stroke={'#E3B266'}
                    classNames="mt-2 ml-1"
                />
            </div>
        </div>
    );
}

export default NextRaceButton;
