import React, { useEffect } from 'react';
import { DriverBio, DriverQuote, Spacer } from '../components';

function Drivers({ data }) {
  const { driversContent, drivers } = data;
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      buildID: 'v1',
      dialect: 'en-us',
      firstTransactionDate: '',
      lastTransactionDate: '',
      memberOrGuest: 'guest',
      pageCategory: 'hertz racing gold',
      pageName: '/drivers',
      pageType: 'drivers',
      pos: 'us',
      returningCustomer: '',
      systemID: 'digital',
      userId: '',
      dropoffCorpFranchise: '',
      dropoffCountryCode: '',
      dropoffDate_mmddyy: '',
      'dropoffDate_yyyy-mm-dd': '',
      dropoffDay: '',
      dropoffDoW: '',
      dropoffLocationName: '',
      dropoffMonth_mm: '',
      dropoffOAGCode: '',
      dropoffState: '',
      dropoffTime24HR: '',
      dropoffYear_yyyy: '',
      isDomestic: '',
      isOneWay: '',
      pcCode: '',
      pickupCity: '',
      pickupCorpFranchise: '',
      pickupCountryCode: '',
      pickupDate_mmddyy: '',
      'pickupDate_yyyy-mm-dd': '',
      pickupDay: '',
      pickupDoW: '',
      pickupLocationName: '',
      pickupMonth_mm: '',
      pickupOAGCode: '',
      pickupState: '',
      pickupTime24HR: '',
      pickupYear_yyyy: '',
      rateCode: '',
      rentalDays: '',
      reservationProcess: '',
      vehicleBaseRateConverted: '',
      vehicleClass: '',
      vehicleCollectionName: '',
      vehiclePremiumStandard: '',
      vehicleType: '',
      voucherNumber: '',
      taxAmountConverted: '',
      timeOfPayment: '',
      totalFeesConverted: '',
      transactionType: '',
      memberIsUsingPoints: '',
      reservationLocationType: '',
      tripType: '',
      countryResidence: '',
      memberID: '',
      availablePoints: '',
      loginStatus: '',
      language: 'en',
    });
  }, []);
  return (
    <div className="">
      <DriverBio drivers={drivers[0]} driversContent={driversContent} />
      <Spacer />
      <DriverQuote {...data.drivers[0]} />
      {/* <Spacer />
			<Headline /> */}
      <Spacer />
      <DriverBio drivers={drivers[1]} driversContent={driversContent} />
      <Spacer />
      <DriverQuote {...data.drivers[1]} />
      {/* <Spacer />
			<Headline /> */}
      <Spacer />
      <DriverBio drivers={drivers[2]} driversContent={driversContent} />
      <Spacer />
      <DriverQuote {...data.drivers[2]} />
      {/* <Spacer />
			<Headline /> */}
      <Spacer />
    </div>
  );
}

export default Drivers;
