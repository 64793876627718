import React from 'react';
import Badge from './Badge';
import { Link } from 'react-router-dom';
import Icon from './Icon';

function ApparelSection() {
    const apparelSectionContent = {
        storeLink:
            'https://grandstandmerchandise.com/collections/hertz-team-jota',
        subHeading: 'Hertz Team Jota',
        heading: 'New Apparel',
        buttonText: 'Shop the store now',
        buttonLink:
            'https://grandstandmerchandise.com/collections/hertz-team-jota',
        mobileImage:
            'https://hertz.com/content/dam/hertz/global/racing-gold/apparel-mob.png',
        mobileImageAlt: 'Model wearing Hertz Team Jota apparel',
        desktopImage:
            'https://hertz.com/content/dam/hertz/global/racing-gold/apparel-dt.png',
        desktopImageAlt: 'Two models wearing Hertz Team Jota apparel',
    };

    return (
        <section className="bg-black">
            <Link
                className="bg-black min-h-[560px] relative flex sm:justify-center "
                to={apparelSectionContent.storeLink}
                target="_blank"
            >
                <div className=" bottom-0 left-0 right-0 text-white absolute sm:static p-[20px] sm:min-w-[60%] md:min-w-[50%] lg:min-w-[40%] xl:min-w-[30%] lg:pt-[80px] xl:pt-[150px]">
                    {/* Badge */}
                    <Badge styles={'p-4 w-[140px] mx-auto mb-[20px]'} />
                    {/* sub head */}
                    <p className="text-[16px] md:text-[20px] color-[#E3B266] text-center block tracking-[3.2px] uppercase">
                        {apparelSectionContent.subHeading}
                    </p>
                    {/* heading */}
                    <h2 className="text-[40px] mb-[40px] text-center tracking-[8px] uppercase">
                        {apparelSectionContent.heading}
                    </h2>
                    {/* button */}
                    <button
                        // to={apparelSectionContent.buttonLink}
                        className="bg-black uppercase tracking-[3px] justify-center pt-[14px] max-w-[70%] text-white flex mx-auto p-[20px] mb-[100px] sm:mb-0"
                    >
                        <span className="mr-2">
                            {apparelSectionContent.buttonText}
                        </span>
                        <Icon classNames="pt-1" stroke="#E3B266" />
                    </button>
                </div>

                {/* Desktop */}
                <div className="hidden sm:block ">
                    <img
                        className="object-cover h-full"
                        alt={apparelSectionContent.desktopImageAlt}
                        src={apparelSectionContent.desktopImage}
                    />
                </div>
                {/* Mobile */}
                <div className="sm:hidden min-h-[250px] max-h-[800px]">
                    <img
                        className="mx-auto h-auto w-full object-cover"
                        alt={apparelSectionContent.mobileImageAlt}
                        src={apparelSectionContent.mobileImage}
                    />
                </div>
            </Link>
        </section>
    );
}

export default ApparelSection;
