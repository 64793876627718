import React from "react";
import Icon from "./Icon";
import { Link } from "react-router-dom";

function Season({ raceResults, season }) {
  const { title, heading } = season;

  return (
    <section
      id="schedule"
      className="bg-season bg-contain bg-no-repeat bg-top bg-black  min-h-[780px] px-[24px] pt-[130px] pb-[80px]"
    >
      <div className="md:max-w-[1640px] mx-auto">
        <div className="md:max-w-[540px]">
          <h2 className="text-[16px] uppercase tracking-[4px] text-[#F5F5F5]">
            {title}
          </h2>
          <span className="block text-[40px] md:text-[64px] uppercase tracking-[8px] border-b border-[#45484A] pb-[12px] mb-[32px] font-bold text-[#F5F5F5]">
            {heading}
          </span>
          <div>
            {raceResults.map((result, index) => {
              return (
                <div
                  key={index}
                  className="border-b border-[#45484A] pb-[24px] mb-[24px]"
                >
                  <div className="flex justify-between items-center">
                    <span className="text-[#FFD100] text-[24px] font-bold">
                      {result.raceNumber}
                    </span>
                    {result.raceFinished && (
                      <span className="text-[#FFD100]"> {result.placing}</span>
                    )}
                  </div>
                  <span
                    className={`${
                      result.raceFinished ? "text-[#767676]" : "text-[#D9D9D9]"
                    } block text-[24px] leading-[25px] mb-[8px]`}
                  >
                    {result.date}
                  </span>
                  <div className=" flex items-center">
                    <div style={{ fontSize: "3rem" }}>{result.flag}</div>
                    <span
                      className={`ml-[12px] text-[36px] ${
                        result.raceFinished
                          ? "text-[#767676]"
                          : "text-[#D9D9D9]"
                      }`}
                    >
                      {" "}
                      {result.raceCourse}
                    </span>
                  </div>

                  <span className="block tracking-[3px] uppercase text-[16px] text-[#9D9EA3]">
                    {result.duration}
                  </span>

                  {result?.link?.linkAddress && result?.link?.linkText && (
                    <Link
                      to={`${result.link.linkAddress}`}
                      className="uppercase tracking-[3px] pt-[14px] flex text-white"
                    >
                      <span className="mr-2">{result.link.linkText}</span>
                      <Icon classNames="pt-1" stroke="#E3B266" />
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Season;
