import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Footer, Navbar } from "./components";
import Home from "./pages/Home";
import Drivers from "./pages/Drivers";
import { Routes, Route, Navigate } from "react-router-dom";
import Article from "./pages/Article";
import Articles from "./pages/Articles";
import Racing from "./pages/Racing";
import useFetcher from "./hooks/useFetcher";
// import useLocalStorage from './hooks/useLocalStorage';
import Loading from "./components/loading/Loading";
import Countdown from "./components/Countdown";

const URL = process.env.REACT_APP_API_URL;

function App() {
  const { pathname } = useLocation();

  const lang = "en";

  // useEffect(() => {
  //     const lang = localStorage.getItem("lang") || "en";
  //     setLang(lang);

  //     localStorage.setItem("lang", lang);
  // }, [lang]);

  const { data, error, loading } = useFetcher(
    `${URL}/content?sectionType=main&sectionLanguage=${lang}`
  );

  // console.log('data', data);

  if (error) console.error(error);

  // useEffect to create canonical link tag with current pathname

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "canonical";
    link.href = `https://www.hertz.com${pathname}`;
    document.head.appendChild(link);
    // create meta tag with current pathname
    const meta = document.createElement("meta");
    meta.name = "og:url";
    meta.content = `https://www.hertz.com${pathname}`;
    document.head.appendChild(meta);
    return () => {
      document.head.removeChild(link);
      document.head.removeChild(meta);
    };
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  if (loading) return <Loading loadingText={"Loading..."} />;

  const countdownBanner = {
    countdownContent: {
      isLiveText: "is currently Live!",
    },

    raceSchedule: [
      {
        startDate: "2024-03-02T05:00:00",
        endDate: "2024-03-03T00:00:00",
        raceName: "Qatar",
      },
      {
        startDate: "2024-04-01T05:00:00",
        endDate: "2024-04-02T00:00:00",
        raceName: "Italy",
      },
      {
        startDate: "2024-05-11T05:00:00",
        endDate: "2024-05-12T00:00:00",
        raceName: "Spa-Belgium",
      },
      {
        startDate: "2024-06-15T05:00:00",
        endDate: "2024-06-17T00:00:00",
        raceName: "Le Mans",
      },
      {
        startDate: "2024-07-14T05:00:00",
        endDate: "2024-07-15T00:00:00",
        raceName: "Brazil",
      },
      {
        startDate: "2024-09-01T05:00:00",
        endDate: "2024-09-02T00:00:00",
        raceName: "USA",
      },
      {
        startDate: "2024-09-15T05:00:00",
        endDate: "2024-09-16T00:00:00",
        raceName: "Japan",
      },
      {
        startDate: "2024-11-02T05:00:00",
        endDate: "2024-11-03T00:00:00",
        raceName: "Bahrain",
      },
    ],
  };

  const raceNav = {
    navTitle: "races",
    // raceLinks: [
    //   {
    //     raceNumber: "R1",
    //     raceName: "Sebring",
    //     flag: "US",
    //     link: "sebring",
    //   },
    //   {
    //     raceNumber: "R2",
    //     raceName: "Portimão",
    //     flag: "Portugal",
    //     link: "portimao",
    //   },
    //   {
    //     raceNumber: "R3",
    //     raceName: "Spa-Francorchamps",
    //     flag: "Belgium",
    //     link: "spa-francorchamps",
    //   },
    //   {
    //     raceNumber: "R4",
    //     raceName: "Le Mans",
    //     flag: "France",
    //     link: "le-mans",
    //   },
    //   {
    //     raceNumber: "R5",
    //     raceName: "Monza",
    //     flag: "Italy",
    //     link: "monza",
    //   },
    //   {
    //     raceNumber: "R6",
    //     raceName: "Fuji",
    //     flag: "Japan",
    //     link: "fuji",
    //   },
    //   {
    //     raceNumber: "R7",
    //     raceName: "Bahrain",
    //     flag: "Bahrain",
    //     link: "bahrain",
    //   },
    // ],
    raceLinks: [],
  };

  return (
    <div>
      <Countdown countdownBanner={countdownBanner} />
      <Navbar data={data} raceNav={raceNav} />
      <Routes>
        <Route
          path="/us/en/racing-gold/"
          element={<Home data={data || {}} />}
        />
        <Route
          path="/us/en/racing-gold/drivers"
          element={<Drivers data={data || {}} />}
        />
        <Route
          path="/us/en/racing-gold/news"
          element={<Articles data={data || {}} />}
        />
        <Route
          path="/us/en/racing-gold/news/:id"
          element={<Article data={data || {}} />}
        />
        <Route path="us/en/racing-gold/racing/:raceName" element={<Racing />} />
        <Route
          path="*"
          element={<Navigate to="/us/en/racing-gold/" replace />}
        />
      </Routes>
      <Footer footerContent={data.footerContent} />
    </div>
  );
}

export default App;
