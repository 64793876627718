import React from "react";

function RacingReport({ raceReportContent }) {
    const { title, overview, details, description } = raceReportContent;
    const { podiumText, podiumValue, locationText, locationValue, distanceText, distanceValue } = overview;
    const {
        lapsText,
        lapsValue,
        timeText,
        timeValue,
        gapText,
        gapValue,
        bestLapText,
        bestLapValue,
        bestSpeedText,
        bestSpeedValue,
    } = details;

    return (
        <div
            className={`py-[62px] px-[20px] bg-black text-white text-center lg:min-h-[940px] bg-no-repeat bg-top bg-raceReportBg`}>
            <div className="mt-[50px] lg:max-w-[1640px] lg:mx-auto lg:p-[56px]  lg:my-[120px] lg:bg-[rgba(0,0,0,0.5)] ">
                <div className="px-0 lg:px-[80px]">
                    <h2 className="text-[#FFCC00] text-[38px] tracking-[0.2em] font-bold uppercase border-b-1 border-[#BB8417] mb-[16px]">
                        {title}
                    </h2>
                    <div className="mb-[40px] text-[18px] lg:tracking-[0.2em] lg:flex lg:justify-center">
                        <div className="uppercase mb-[24px] lg:mr-12">
                            <span className="mr-[16px] font-bold">{podiumText}</span>
                            <span>{podiumValue}</span>
                        </div>
                        <div className="uppercase mb-[24px] lg:tracking-[0.2em] lg:mr-12">
                            <span className="mr-[16px] font-bold">{locationText}</span>
                            <span>{locationValue}</span>
                        </div>
                        <div className="uppercase lg:tracking-[0.2em]">
                            <span className="mr-[16px] font-bold">{distanceText}</span>
                            <span>{distanceValue}</span>
                        </div>
                    </div>
                </div>
                <div className="uppercase p-[30px] border-2 border-[#45484A] mb-[40px] lg:flex lg:justify-center">
                    <div className="flex flex-col mx-auto justify-center align-center border-b-2 lg:border-b-0 lg:border-r-2 max-w-[200px] lg:max-w-[300px] lg:px-[60px] border-[#45484A] lg:mx-0">
                        <span className="font-bold text-[32px]">{lapsValue}</span>
                        <span className="text-[12px] text-[#adadad] pb-[12px] tracking-[0.1em]">{lapsText}</span>
                    </div>
                    <div className="flex flex-col mx-auto justify-center align-center border-b-2 max-w-[200px] lg:border-b-0 lg:max-w-[300px] lg:px-[60px] lg:border-r-2 border-[#45484A] pt-[12px] lg:mx-0">
                        <span className="font-bold text-[32px]">{timeValue}</span>
                        <span className="text-[12px] text-[#adadad] pb-[12px] tracking-[0.1em]">{timeText}</span>
                    </div>
                    <div className="flex flex-col mx-auto justify-center align-center border-b-2 max-w-[200px] lg:border-b-0 lg:max-w-[300px] lg:px-[60px] lg:border-r-2 border-[#45484A] pt-[12px] lg:mx-0">
                        <span className="font-bold text-[32px]">{gapValue}</span>
                        <span className="text-[12px] text-[#adadad] pb-[12px] tracking-[0.1em]">{gapText}</span>
                    </div>
                    <div className="flex flex-col mx-auto justify-center align-center border-b-2 max-w-[200px] lg:border-b-0 lg:max-w-[300px] lg:px-[60px] lg:border-r-2 border-[#45484A] pt-[12px] lg:mx-0">
                        <span className="font-bold text-[32px]">{bestLapValue}</span>
                        <span className="text-[12px] text-[#adadad] pb-[12px] tracking-[0.1em]">{bestLapText}</span>
                    </div>
                    <div className="flex flex-col mx-auto justify-center align-center pt-[12px] lg:border-0 lg:max-w-[300px] lg:px-[60px] lg:mx-0">
                        <span className="font-bold text-[32px]">{bestSpeedValue}</span>
                        <span className="text-[12px] text-[#adadad] tracking-[0.1em]">{bestSpeedText}</span>
                    </div>
                </div>
                {description ? (
                    <div className="px-0 lg:px-[80px] lg:pb-[40px]">
                        <p className="lg:columns-2 gap-x-6 text-left text-[20px] leading-[150%]">{description}</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default RacingReport;
