import { NavLink } from 'react-router-dom';

function BreadcrumbsMenu({ breadcrumbs }) {
    // console.log('breadcrumbs', breadcrumbs);
    const { breadcrumbItems, bredcrumbTitle } = breadcrumbs;
    // const items = [
    // 	{ title: 'Home', link: '/', className: 'font-[600] text-14px' },
    // 	{
    // 		title: 'News',
    // 		link: '/news',
    // 		className: 'font-[400] text-14px text-[#D9D9D9]',
    // 	},
    // ];

    // var one = {
    // 	"breadcrumbs": {
    // 		"bredcrumbTitle": "News",
    // 		"breadcrumbItems": [
    // 			{"title": "Home", "link": "/", "className": "font-[600] text-14px"},
    // 			{"title": "News", "link": "/news", "className": "font-[400] text-14px text-[#D9D9D9]"}
    // 		]
    // 	}
    // }

    return (
        <div className="bg-articles_bg bg-cover bg-no-repeat px-[24px]">
            <div className=" md:max-w-[1640px] md:mx-auto py-10 ">
                <div className="flex text-white">
                    {breadcrumbItems.map((item, index) => (
                        <>
                            <NavLink
                                className={item.className}
                                key={index}
                                to={item.link}
                            >
                                {item.title}
                            </NavLink>
                            {index !== breadcrumbItems.length - 1 && (
                                <span className="px-4 flex items-center justify-center font-[400]">
                                    {' '}
                                    <svg
                                        width="6"
                                        height="11"
                                        viewBox="0 0 6 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.43359 1.15625L5.34375 5.3125C5.45312 5.44922 5.53516 5.61328 5.53516 5.75C5.53516 5.91406 5.45312 6.07812 5.34375 6.21484L1.43359 10.3711C1.1875 10.6445 0.777344 10.6445 0.503906 10.3984C0.230469 10.1523 0.230469 9.74219 0.476562 9.46875L3.97656 5.75L0.476562 2.05859C0.230469 1.78516 0.230469 1.375 0.503906 1.12891C0.777344 0.882812 1.1875 0.882812 1.43359 1.15625Z"
                                            fill="#E3B266"
                                        />
                                    </svg>
                                </span>
                            )}
                        </>
                    ))}
                </div>
                <div>
                    <h1 className="text-[40px] sm:text-[64px] font-[600] text-white">
                        {bredcrumbTitle}
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default BreadcrumbsMenu;
