// driver bio component

import { Spacer } from '.';

function DriverBio({ drivers, driversContent }) {
    // console.log('drivers: ', drivers, 'driversContent: ', driversContent);
    const {
        name,
        country,
        homeTown,
        birthDate,
        position,

        yearsRacing,
        championships,
        wins,
        podiums,
        aboutHeading,
        body,
        mobileImage,
        image,
    } = drivers;
    const {
        countryText,
        hometown,
        dob,
        yearsRacing: yearsRacingLabel,
        championship,
        winsText,
        podiumsText,
        worldEndurance,
    } = driversContent;

    const hasDriverStats = () => {
        if (
            yearsRacing !== '' &&
            championships !== '' &&
            wins !== '' &&
            podiums !== ''
        ) {
            return (
                <div className={`${name === 'Yifei Ye' ? 'hidden' : ''}`}>
                    <div className=" py-1 flex justify-between border-b-[.5px] border-[#45484A]">
                        <div className="px-2 w-[50%] border-r-[1px] border-[#45484A]">
                            <p className="text-hertz_yellow text-[32px]">
                                {yearsRacing}
                            </p>
                            <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                {yearsRacingLabel}
                            </label>
                        </div>
                        <div className="px-2 w-[50%]">
                            <p className="text-hertz_yellow text-[32px]">
                                {championships}
                            </p>
                            <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                {championship}
                            </label>
                        </div>
                    </div>
                    <div className="py-3 flex">
                        <div className="px-2 pr-10 border-r-[1px] border-[#45484A]">
                            <p className="text-hertz_yellow text-[32px]">
                                {wins}
                            </p>
                            <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                {winsText}
                            </label>
                        </div>
                        <div className="px-2 pl-10">
                            <p className="text-hertz_yellow text-[32px]">
                                {podiums}
                            </p>
                            <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                {podiumsText}
                            </label>
                        </div>
                    </div>
                </div>
            );
        } else {
            return false;
        }
    };
    return (
        <div className="">
            {/* mobile */}
            <div className="md:hidden">
                <div
                    className={`
            ${
                false
                    ? 'bg-driver_background_left'
                    : 'bg-driver_background_right'
            } 
                        min-h-[700px] relative bg-auto bg-no-repeat bg-center-right`}
                >
                    <div className="py-14 px-6 ">
                        <div className="">
                            <h1 className="text-white text-[60px] ss:text-[70px]  leading-[65px] uppercase">
                                {name}
                            </h1>
                        </div>
                        <div className="sm:ml-auto">
                            <div className="pt-5">
                                <label className="text-light_gray300 text-[14px] text-left">
                                    {countryText}
                                </label>
                                <p className="text-light_gray100 text-[24px] ss:text-[28px] -mt-2">
                                    {country}
                                </p>
                            </div>
                            <div className="pt-5">
                                <label className="text-light_gray300 text-[14px] text-left">
                                    {hometown}
                                </label>
                                <p className="text-light_gray100 text-[24px] ss:text-[28px] -mt-2">
                                    {homeTown}
                                </p>
                            </div>
                            <div className="pt-5">
                                <label className="text-light_gray300 text-[14px] text-left">
                                    {dob}
                                </label>
                                <p className="text-light_gray100 text-[24px] ss:text-[28px] -mt-2">
                                    {birthDate}
                                </p>
                            </div>
                            {/* <div className='pt-5 flex'>
								{socials.map((social, index) => {
									return (
										<Icon
											key={index}
											fill='#E3B266'
											path={social.name}
											href={social.url}
											classNames='mr-3 hover:opacity-50'
										/>
									);
								})}
							</div> */}
                        </div>
                    </div>
                    <div className="absolute right-0 bottom-0 w-full max-w-[280px] xs:max-w-[400px]">
                        <img className="w-full " src={mobileImage} alt="" />
                    </div>
                </div>
                <Spacer />
                {/* Drive stats */}
                <div className="bg-black py-[80px] px-[24px]">
                    {hasDriverStats()}

                    <div className=" py-2 text-light_gray100 text-[18px]">
                        <h3 className="py-3">
                            {aboutHeading} <br />
                        </h3>
                        <p className="font-thin">{body}</p>
                        <p className="text-light_gray500 text-[12px] pt-3">
                            {worldEndurance}
                        </p>
                    </div>
                </div>
            </div>
            {/* desktop */}
            <div className="hidden md:block relative">
                <div
                    className={`
            ${
                position === 'left'
                    ? 'bg-driver_background_left'
                    : 'bg-driver_background_right'
            } 
                        min-h-[700px]
                        max-h-[700px]
                        bg-cover
                        bg-right
                        `}
                >
                    <div
                        className={`flex ${
                            position === 'left' ? '' : 'flex-row-reverse'
                        } max-w-[1640px] mx-auto`}
                    >
                        <div className="py-14 px-2 w-[95%] lg2:w-[65%]">
                            <div className="">
                                <h1
                                    className={`text-white text-center ${
                                        position === 'left'
                                            ? 'md:text-right'
                                            : 'md:text-left'
                                    } md:text-[85px] lg:text-[90px] leading-[110px] tracking-widest uppercase`}
                                >
                                    {name}
                                </h1>
                            </div>
                            <div className={`flex justify-center`}>
                                <div>
                                    <div className="pt-5">
                                        <label className="text-light_gray300 text-[14px] text-left">
                                            {countryText}
                                        </label>
                                        <p className="text-light_gray100 text-[32px] -mt-2">
                                            {country}
                                        </p>
                                    </div>
                                    <div className="pt-5">
                                        <label className="text-light_gray300 text-[14px] text-left">
                                            {hometown}
                                        </label>
                                        <p className="text-light_gray100 text-[32px] -mt-2">
                                            {homeTown}
                                        </p>
                                    </div>
                                    <div className="pt-5">
                                        <label className="text-light_gray300 text-[14px] text-left">
                                            {dob}
                                        </label>
                                        <p className="text-light_gray100 text-[32px] -mt-2">
                                            {birthDate}
                                        </p>
                                    </div>
                                    {/* <div className='pt-5 flex relative z-40'>
										{socials.map((social, index) => {
											return (
												<Icon
													key={index}
													fill='#E3B266'
													path={social.name}
													href={social.url}
													classNames='mr-3 hover:opacity-50'
												/>
											);
										})}
									</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="w-[40%]"></div>
                    </div>
                </div>
                <Spacer />
                <div className="bg-black">
                    <div
                        className={`bg-black flex  max-w-[1640px] mx-auto ${
                            position === 'right' ? '' : 'flex-row-reverse'
                        }`}
                    >
                        <div className="hidden sm:w-[40%] md:w-[50%] sm:block ">
                            {/* <img
								className='w-full h-full'
								src='src/assets/bio_image.png'
								alt='race car'
							/> */}
                        </div>
                        <div className="sm:w-[60%] md:w-[50%] flex justify-end pb-10">
                            <div className="w-[90%]">
                                <div
                                    className={`${
                                        name === 'Yifei Ye' ? 'hidden' : ''
                                    } px-3 pt-10`}
                                >
                                    <div className="sm:hidden">
                                        <div className=" py-1 flex justify-between border-b-[.5px] border-[#45484A]">
                                            <div className="px-2 w-[50%] border-r-[1px] border-[#45484A]">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {yearsRacing}
                                                </p>
                                                <label className="text-light_gray500 text-[14px] tracking-[.1rem]">
                                                    {yearsRacingLabel}
                                                </label>
                                            </div>
                                            <div className="px-2 w-[50%]">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {championships}
                                                </p>
                                                <label className="text-light_gray500 text-[14px] tracking-[.1rem]">
                                                    {championship}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="py-3 flex">
                                            <div className="px-2 pr-10 border-r-[1px] border-[#45484A]">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {wins}
                                                </p>
                                                <label className="text-light_gray500 text-[14px] tracking-[.1rem]">
                                                    {winsText}
                                                </label>
                                            </div>
                                            <div className="px-2 pl-10">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {podiums}
                                                </p>
                                                <label className="text-light_gray500 text-[14px] tracking-[.1rem]">
                                                    {podiumsText}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden sm:block">
                                        <div className="py-3 flex">
                                            <div className="pr-8 border-r-[1px] border-[#45484A]">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {yearsRacing}
                                                </p>
                                                <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                                    {yearsRacingLabel}
                                                </label>
                                            </div>
                                            <div className="px-8 border-r-[1px] border-[#45484A]">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {championships}
                                                </p>
                                                <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                                    {championship}
                                                </label>
                                            </div>
                                            <div className=" px-8 border-r-[1px] border-[#45484A]">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {wins}
                                                </p>
                                                <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                                    {winsText}
                                                </label>
                                            </div>
                                            <div className="pl-8 ">
                                                <p className="text-hertz_yellow text-[32px]">
                                                    {podiums}
                                                </p>
                                                <label className="text-light_gray500 text-[12px] tracking-[.1rem]">
                                                    {podiumsText}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-3 py-2 text-light_gray100 text-[18px] leading-[27px] sm:w-[95%]">
                                    <h3 className="py-3">
                                        {aboutHeading} <br />
                                    </h3>
                                    <p className="font-thin">{body}</p>
                                    <p className="text-light_gray500 text-[12px] pt-3">
                                        {worldEndurance}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`w-full absolute bottom-0 z-[5px] flex ${
                        position === 'right' ? '' : 'flex-row-reverse'
                    } justify-between `}
                >
                    <div className="w-full max-w-[1640px] mx-auto overflow-hidden">
                        <div className="w-full">
                            <div className="ml-auto w-full">
                                <img
                                    className={`w-[52%] min-h-[1000px] min-w-[750px] ${
                                        position === 'left'
                                            ? 'ml-auto md:mr-[-100px] lg2:mr-0'
                                            : 'mr-auto md:ml-[-158px] lg2:ml-0'
                                    } `}
                                    src={image}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DriverBio;
