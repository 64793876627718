import React from "react";

function DateTimeDisplay({ value, type }) {
    return (
        <div className="text-center">
            <p className="text-[32px] md:text-[48px] font-bold leading-[24px] md:leading-[48px]">{value}</p>
            <span className="text-[12px] md:text-[16px] uppercase text-[#45484A] tracking-[0.2em]">{type}</span>
        </div>
    );
}

export default DateTimeDisplay;
